// Generated by Framer (77e91d3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["K63ql5aci", "swLw7mI3F"];

const serializationHash = "framer-zuZp7"

const variantClassNames = {K63ql5aci: "framer-v-14nay2t", swLw7mI3F: "framer-v-1af8jnt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Menu Close": "K63ql5aci", "Menu Open": "swLw7mI3F"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, cysd_f03b: tap ?? props.cysd_f03b, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "K63ql5aci"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cysd_f03b, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "K63ql5aci", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapbtvm49 = activeVariantCallback(async (...args) => {
if (cysd_f03b) {
const res = await cysd_f03b(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zuZp7", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-14nay2t", className)} data-framer-name={"Menu Close"} data-highlight layoutDependency={layoutDependency} layoutId={"K63ql5aci"} onTap={onTapbtvm49} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0.9)", borderBottomLeftRadius: 64, borderBottomRightRadius: 64, borderTopLeftRadius: 64, borderTopRightRadius: 64, ...style}} {...addPropertyOverrides({swLw7mI3F: {"data-framer-name": "Menu Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-e6ldei"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"CG34oolDB"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{swLw7mI3F: {rotate: 45}}}/><motion.div className={"framer-1t6u4di"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"PQYiKq2ma"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2}}/><motion.div className={"framer-18d9536"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"Kv3x7HpIW"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{swLw7mI3F: {rotate: -45}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zuZp7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zuZp7 .framer-1jsuspl { display: block; }", ".framer-zuZp7 .framer-14nay2t { cursor: pointer; height: 40px; overflow: hidden; position: relative; width: 40px; will-change: var(--framer-will-change-override, transform); }", ".framer-zuZp7 .framer-e6ldei { flex: none; height: 2px; left: calc(50.00000000000002% - 19px / 2); position: absolute; top: 14px; width: 19px; }", ".framer-zuZp7 .framer-1t6u4di { flex: none; height: 2px; left: calc(50.00000000000002% - 19px / 2); position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 19px; }", ".framer-zuZp7 .framer-18d9536 { bottom: 14px; flex: none; height: 2px; left: calc(50.00000000000002% - 19px / 2); position: absolute; width: 19px; }", ".framer-zuZp7.framer-v-1af8jnt .framer-e6ldei { top: calc(50.00000000000002% - 2px / 2); }", ".framer-zuZp7.framer-v-1af8jnt .framer-1t6u4di { left: calc(50.00000000000002% - 1px / 2); width: 1px; }", ".framer-zuZp7.framer-v-1af8jnt .framer-18d9536 { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"swLw7mI3F":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"cysd_f03b":"tap"}
 * @framerImmutableVariables true
 */
const FramerDSvTaPviJ: React.ComponentType<Props> = withCSS(Component, css, "framer-zuZp7") as typeof Component;
export default FramerDSvTaPviJ;

FramerDSvTaPviJ.displayName = "Icon Menu";

FramerDSvTaPviJ.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerDSvTaPviJ, {variant: {options: ["K63ql5aci", "swLw7mI3F"], optionTitles: ["Menu Close", "Menu Open"], title: "Variant", type: ControlType.Enum}, cysd_f03b: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerDSvTaPviJ, [])